import { axios } from '@/utils/request'

export function getlist(params){
    return axios({
        url: '/business/ysLeanUnderlyingLogic/list',
        method: 'get',
        params
      })
}

export function add(params){
    return axios({
        url: '/business/ysLeanUnderlyingLogic/add',
        method: 'post',
        data: params
      })
}


export function edit(params){
    return axios({
        url: '/business/ysLeanUnderlyingLogic/edit',
        method: 'put',
        data: params
      })
}


export function del(params) {
    return axios({
      url: '/business/ysLeanUnderlyingLogic/delete',
      method: 'delete',
      params: params
    })
  }

  export function deleteBatch(params) {
    return axios({
      url: '/business/ysLeanUnderlyingLogic/deleteBatch',
      method: 'delete',
      params: params
    })
  }